<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col sm="12" cols="12">
          <div
            style="
              background-color: #fff;
              padding: 20px 20px;
              border-radius: 10px;
            "
          >
            <v-col sm="12" cols="12">
              <v-row>
                <v-col sm="12" cols="12" class="py-0">
                  <h2 class="mb-0">
                    {{ $t("manage_academic_years") }}
                  </h2>
                  <v-snackbar
                    :color="snackbar.color"
                    v-model="snackbar.show"
                    right
                  >
                    {{ snackbar.message }}
                  </v-snackbar>
                  <v-dialog v-model="dialog" max-width="500px" persistent>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="float-right"
                        style="background-color: #d6d0fc"
                      >
                        <span style="font-size: 1em; color: #3316f2">
                          <i class="fas fa-user-plus">
                            <label class="ml-2 btn_create_new">{{
                              $t("create_academic_years")
                            }}</label>
                          </i>
                        </span>
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title v-if="this.academicYearId !== ''">{{
                        $t("edit_academic_years")
                      }}</v-card-title>
                      <v-card-title v-else>{{
                        $t("create_academic_years")
                      }}</v-card-title>

                      <v-divider />
                      <v-card-text
                        style="
                          height: 400x;
                          background-color: #edf1f5;
                          color: #333;
                        "
                      >
                        <v-container>
                          <v-row>
                            <v-col sm="12" cols="12" class>
                              <label class="label">{{ $t("name_en") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_en"
                                placeholder="e.g Academic year 2018-2019"
                                precision="3"
                                outlined
                                required
                              />
                              <label class="label">{{ $t("name_kh") }}</label>
                              <v-text-field
                                class="disable_alert my-3"
                                v-model="name_kh"
                                placeholder="e.g ឆ្នាំសិក្សា ២០១៨-២០១៩"
                                precision="3"
                                outlined
                                required
                              />
                              <label class="label">{{
                                $t("start_year")
                              }}</label>

                              <v-text-field
                                v-model="start_year"
                                class="disable_alert my-3"
                                placeholder="e.g 2018"
                                precision="3"
                                outlined
                                required
                              >
                              </v-text-field>
                              <label class="label">{{ $t("end_year") }}</label>

                              <v-text-field
                                v-model="end_year"
                                class="disable_alert my-3"
                                placeholder="e.g 2020"
                                precision="3"
                                outlined
                                required
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          class="btn_cancel"
                          @click="(dialog = false), clear()"
                          >{{ $t("cancel") }}</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn_save_close"
                          @click.prevent="onSaveClose()"
                          >{{ $t("save_close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-divider class="ma-2 mt-8 mb-5" color="#e9e9e9"></v-divider>
                <v-col sm="12" cols="12" class="py-0">
                  <template>
                    <v-card>
                      <v-card-title>
                        <v-spacer></v-spacer>
                        <v-col sm="4" cols="12">
                          <v-text-field
                            outlined
                            append-icon="search"
                            label="Search"
                            v-model="search"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-card-title>
                      <v-data-table
                        item-key="id"
                        class="elevation-1"
                        :headers="headers"
                        :items="listAcademicYear"
                        :search="search"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [10, 25, 50, -1],
                        }"
                        hide-default-header
                      >
                        <template v-slot:header="{}">
                          <thead>
                            <tr>
                              <th>{{ $t("no") }}</th>
                              <th>{{ $t("name_en") }}</th>
                              <th>{{ $t("name_kh") }}</th>
                              <th>{{ $t("start_year") }}</th>
                              <th>{{ $t("end_year") }}</th>
                              <th class="text-center">{{ $t("action") }}</th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body="{ items }">
                          <tbody
                            style="white-space: nowrap"
                            v-if="items.length > 0"
                          >
                            <tr
                              v-for="(academicYear, index) in items"
                              v-bind:key="index"
                            >
                              <td>{{ 1 + index++ }}</td>
                              <td>{{ academicYear.name_en }}</td>
                              <td>{{ academicYear.name_kh }}</td>
                              <td>{{ academicYear.start_year }}</td>
                              <td>{{ academicYear.end_year }}</td>

                              <td class="text-center">
                                <v-btn
                                  @click="onEditItem(academicYear)"
                                  color="primary"
                                  fab
                                  class="mx-2"
                                  small
                                >
                                  <v-icon size="15">fa fa-pen</v-icon>
                                </v-btn>
                                <v-btn
                                  @click="deleteacademicyear(academicYear)"
                                  fab
                                  small
                                  class="mx-2"
                                  color="error"
                                >
                                  <v-icon size="15">fa fa-trash</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td
                                :colspan="headers.length + 1"
                                style="text-align: center"
                              >
                                <v-alert dense outlined type="error">
                                  No data available in table
                                </v-alert>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-card>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <div class="loading">
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
          :opacity="0.9"
          background-color="#dedede"
          :width="40"
          :height="40"
        >
          <div v-if="myLoading">
            <img
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/schoolbase.appspot.com/o/images%2Floading.gif?alt=media&token=58553b10-7335-42a6-a0c8-8a6d55927816"
            />
          </div>
        </loading>
      </div>
    </div>
  </v-app>
</template>
<script>
import store from "@/store";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  getAcademicYear,
  createAcademicYear,
  updateAcademicYear,
  deleteAcademicYear,
} from "@schoolbase/web-client-lib";
export default {
  props: ["fullPage", "alertMessage", "color", "alertText"],
  data() {
    return {
      search: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      headers: [
        { text: "No", value: "no" },
        { text: "English Name", value: "name_en" },
        { text: "Khmer Name", value: "name_kh" },
        { text: "Start Year", value: "start_year" },
        { text: "End Year", value: "end_year" },
      ],
      listAcademicYear: [],
      isLoading: false,
      myLoading: false,
      majorGenId: "",
      academicYearId: "",
      start_year: "",
      end_year: "",
      dialog: false,
      token: store.getters.getToken,
      name_en: "",
      name_kh: "",
      years: [],
    };
  },
  components: {
    Loading,
  },
  methods: {
    onEditItem(item) {
      this.name_en = item.name_en;
      this.name_kh = item.name_kh;
      this.academicYearId = item.id;
      this.start_year = parseInt(item.start_year);
      this.end_year = parseInt(item.end_year);
      this.dialog = true;
    },
    // Clear Data After User click cancel or dialoge is false
    clear() {
      this.name_en = "";
      this.name_kh = "";
      this.id = "";
      this.academicYearId = "";
      this.start_year = "";
      this.end_year = "";
    },

    //Create User Function
    genearate() {
      let max = new Date().getFullYear();
      for (let i = max; i > max - 15; i--) {
        this.years.push(i);
      }
      return this.years;
    },
    async onSaveClose() {
      let data = {
        name_kh: this.name_kh,
        name_en: this.name_en,
        start_year: this.start_year,
        end_year: this.end_year,
      };
      if (this.academicYearId) {
        if (this.academicYearId !== "") {
          try {
            const result = await updateAcademicYear(
              this.academicYearId,
              data,
              this.token
            );
            window.console.log("result", result);
            this.loadAcademicYear();
          } catch (e) {
            this.snackbar = {
              message: e,
              color: "error",
              show: true,
            };
          }
        }
      } else {
        try {
          const result = await createAcademicYear(data, this.token);
          window.console.log("result", result);
          this.loadAcademicYear();
        } catch (e) {
          this.snackbar = {
            message: e,
            color: "error",
            show: true,
          };
        }
      }
      this.clear();
      this.dialog = false;
    },
    async loadAcademicYear() {
      try {
        this.isLoading = true;
        this.myLoading = true;
        const APIResponse = await getAcademicYear(this.token);
        this.listAcademicYear = APIResponse.payload;
        if (APIResponse.token) {
          this.$store.commit("LOGGED_TOKEN", APIResponse.token);
        }

        this.isLoading = false;
        this.myLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.myLoading = false;

        // Logout user when unauthorized call
        if (e.response?.status == 401) {
          this.$store.dispatch("logOut");
          this.$router.go({
            name: "SignIn",
          });

          return;
        }

        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };

      }
    },

    async deleteacademicyear(academicYear) {
      const index = this.listAcademicYear.indexOf(academicYear);
      this.deletItems = academicYear;
      const btnCancel = confirm("Are you sure you want to delete this item?");
      if (btnCancel === true) {
        this.listAcademicYear.splice(index, 1);
        await this.deleteAcademicYear();
      }
    },
    async deleteAcademicYear() {
      try {
        await deleteAcademicYear(this.deletItems.id, this.token);
      } catch (e) {
        this.snackbar = {
          message: e,
          color: "error",
          show: true,
        };
      }
    },
  },

  async mounted() {
    this.genearate();
    this.loadAcademicYear();
  },
};
</script>
<style scoped>
.v-data-table thead th {
  font-size: 16px !important;
  text-align: center !important;
}
.v-data-table tbody {
  text-align: center !important;
}

.btn_edit {
  background-color: #4e6470 !important;
  color: #fff !important;
  height: 35px;
}

.v-card__actions .v-btn.v-btn {
  padding: 0 16px;
}

.v-menu__content {
  top: 141px !important;
  left: 1098px !important;
}

.v-menu__content .v-list .v-list-item {
  min-height: 35px !important;
}
.btn_create_new {
  color: #3316f2 !important;
  text-transform: capitalize !important;
  height: 40px !important;
  border: none !important;
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 14px;
}

.tab_wrapper {
  position: relative;
  display: inherit;
}

.v-tab {
  min-width: 30px;
  font-size: 15px;
  text-transform: capitalize;
}

.v-icon--left {
  margin-right: 0px;
}

.dropdown_transactions.v-btn {
  background-color: #2ca01c !important;
  height: 40px !important;
  width: auto !important;
  border-radius: 0 !important;
  padding: 0px 10px 0px 10px !important;
  color: #fff !important;
  right: 12px;
  position: absolute;
  top: 7px;
}

@media (max-width: 576px) {
}
</style>
